import { setBasePath, SlIcon, SlButton, SlDropdown, SlSelect, SlMenu, SlMenuItem, SlDialog, SlSkeleton,
  SlTabGroup, SlTabPanel, SlTab, SlTooltip, SlInput, SlDetails, SlTag, SlCheckbox, SlDrawer }
  from '@shoelace-style/shoelace'

const mode = import.meta.env.MODE || "development"

const paths = {
  production: "",
  development: "-dev",
  test: "-test"
}

let rootUrl = `/vite${paths[mode]}`
setBasePath(rootUrl)

customElements.get('sl-icon') || customElements.define('sl-icon', SlIcon)
customElements.get('sl-button') || customElements.define('sl-button', SlButton)
customElements.get('sl-dropdown') || customElements.define('sl-dropdown', SlDropdown)
customElements.get('sl-select') || customElements.define('sl-select', SlSelect)
customElements.get('sl-menu') || customElements.define('sl-menu', SlMenu)
customElements.get('sl-menu-item') || customElements.define('sl-menu-item', SlMenuItem)
customElements.get('sl-dialog') || customElements.define('sl-dialog', SlDialog)
customElements.get('sl-skeleton') || customElements.define('sl-skeleton', SlSkeleton)
customElements.get('sl-tab-group') || customElements.define('sl-tab-group', SlTabGroup)
customElements.get('sl-tab-panel') || customElements.define('sl-tab-panel', SlTabPanel)
customElements.get('sl-tab') || customElements.define('sl-tab', SlTab)
customElements.get('sl-tooltip') || customElements.define('sl-tooltip', SlTooltip)
customElements.get('sl-input') || customElements.define('sl-input', SlInput)
customElements.get('sl-details') || customElements.define('sl-details', SlDetails)
customElements.get('sl-tag') || customElements.define('sl-tag', SlTag)
customElements.get('sl-checkbox') || customElements.define('sl-checkbox', SlCheckbox)
customElements.get('sl-drawer') || customElements.define('sl-drawer', SlDrawer)
