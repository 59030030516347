export default function signup_with_company () {
  return {
    company_itn: {
      isPresent: {
        check: element => element.value.length > 0,
        message: 'Обязательно для заполнения'
      },
      isValid: {
        check: element => /^\b\d{10}\b|\b\d{12}\b$/i.test(element.value),
        message: 'ИНН должен состоять из 10 или 12 цифр'
      }
    },
    company_psrn: {
      isPresent: {
        check: element => element.value.length > 0,
        message: 'Обязательно для заполнения'
      },
      isValid: {
        check: element => /^\b\d{13}\b|\b\d{15}\b$/i.test(element.value),
        message: 'ОГРН должен состоять из 13 или 15 цифр'
      }
    },
    company_iec: {
      isValid: {
        check: element => {
          if (element.value.length === 0) return true
          return /^\b\d{9}\b$/i.test(element.value)
        },
        message: 'КПП должен состоять из 9 цифр'
      }
    },
    company_rceo: {
      isValid: {
        check: element => {
          if (element.value.length === 0) return true
          return /^\b\d{8}\b|\b\d{10}\b$/i.test(element.value)
        },
        message: 'ОКПО должен состоять из 8 или 10 цифр'
      }
    }
  }
}
