export default function verify_phone () {
  return {
    verification_phone: {
      isPresent: {
        check: element => element.value.length > 0,
        message: 'Обязательно для заполнения'
      },
      isValid: {
        check: element => /^[+]?[0-9]{1,5}[-\s]?[(]?[0-9]{1,5}[)]?[-\s./0-9]{4,15}$/i.test(element.value),
        message: 'Неверный формат'
      }
    }
  }
}
