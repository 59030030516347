export default function order () {
  return {
    order_user_phone: {
      isPresent: {
        check: element => element.value.length > 0,
        message: 'Обязательно для заполнения'
      },
      isValid: {
        check: element => /^[+]?\d{1,5}[-\s]?[(]?\d{1,5}[)]?[-\s./\d]{4,15}$/i.test(element.value),
        message: 'Неверный формат'
      }
    },
    order_user_email: {
      isValid: {
        check: element => element.value.length > 0 &&
          /^[A-ZА-Я\d._%+-]+@[A-ZА-Я\d.-]+\.[A-ZА-Я]{2,4}$/i.test(element.value),
        message: 'Неверный формат'
      }
    },
    order_user_itn: {
      isValid: {
        check: element => element.value.length > 0 && /^\b\d{10}\b|\b\d{12}\b$/i.test(element.value),
        message: 'ИНН должен состоять из 10 или 12 цифр'
      }
    }
  }
}
