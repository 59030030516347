import 'vendor/inputmask.phone/inputmask.phone.extensions'
import 'vendor/inputmask.phone/phone-codes/phone-ru-reduced'

const im = new Inputmask('phone')

export default function proposal () {
  return {
    proposal_description: {
      isPresent: {
        check: element => element.value.length > 0,
        message: 'Обязательно для заполнения'
      }
    },
    proposal_contact: {
      isPresent: {
        check: element => element.value.length > 0,
        message: 'Обязательно для заполнения'
      },
      isValid: {
        check: element => {
          if (/^[a-zA-Zа-яА-Я]+$/.test(element.value[0])) {
            if (element.inputmask) element.inputmask.remove()
            return /^[A-ZА-Я0-9._%+-]+@[A-ZА-Я0-9.-]+\.[A-ZА-Я]{2,4}$/i.test(element.value)
          }
          if (/^[0-9+]+$/.test(element.value[0])) {
            im.mask(element)
            return /^[+]?[0-9]{1,5}[-\s]?[(]?[0-9]{1,5}[)]?[-\s./0-9]{4,15}$/i.test(element.value)
          }
        },
        message: 'Неверный формат'
      }
    }
  }
}
