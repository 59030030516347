import { application } from '../controllers/application'

const controllers = import.meta.glob(
  "../controllers/*_controller.js",
  { eager: true }
)

for (let path in controllers) {
  let module = controllers[path]

  let name = path
    .replace(/^\..\/controllers\//, '')
    .replace(/_controller\.js$/, '')
    .replace(/\//g, '_')

  application.register(name, module.default)
}
