import 'vendor/inputmask.phone/inputmask.phone.extensions'
import 'vendor/inputmask.phone/phone-codes/phone-ru'
// import 'vendor/inputmask.phone/phone-codes/phone-ru-reduced'

const im = new Inputmask('phone')

export default function phone_masking () {
  const phoneFields = document.querySelectorAll("[type='tel']")

  phoneFields.forEach(el => {
    if (el.type === 'tel' && el.getAttribute('inputmode') !== 'tel') im.mask(el)
  })
}
