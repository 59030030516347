export default function confirm_code () {
  return {
    confirmation_code: {
      isPresent: {
        check: element => element.value.length > 0,
        message: 'Обязательно для заполнения'
      },
      isValid: {
        check: element => /^\d{4}$/i.test(element.value),
        message: 'Неверный формат'
      }
    }
  }
}
