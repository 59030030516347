import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize() {
    this.touchCoords = {
      start: {
        x: 0,
        y: 0
      },
      stop: {
        x: 0,
        y: 0
      }
    }
  }

  connect() {
    if (!this.element) return false

    const body = document.body
    const classes = this.element.classList.value
    if (classes.includes('mobile_view')) {
      body.addEventListener('touchstart', e => this.touchStart(e), false)
      body.addEventListener('touchend', e => this.touchStop(e), false)
      // el.addEventListener("touchcancel", handleCancel, false)
      // el.addEventListener("touchmove", handleMove, false)
    } else {
      if (classes.includes('opened') || !classes.includes('closed')) {
        body.classList.add('content_narrow')
        body.classList.remove('content_wide')
      } else if (classes.includes('closed')) {
        body.classList.add('content_wide')
        body.classList.remove('content_narrow')
      }
    }
  }

  touchStart(e) {
    this.touchCoords = {
      ...this.touchCoords,
      start: { x: e.changedTouches[0].pageX, y: e.changedTouches[0].pageY }
    }
  }

  touchStop(e) {
    this.touchCoords = {
      ...this.touchCoords,
      stop: { x: e.changedTouches[0].pageX, y: e.changedTouches[0].pageY }
    }

    const { x: startX } = this.touchCoords.start
    const { x: stopX } = this.touchCoords.stop
    const deltaX = startX - stopX
    if (Math.abs(deltaX) > 120) {
      const mobileMenu = document.getElementById('side_mobile_menu')
      if (!mobileMenu) return false

      if (deltaX < 0)
        mobileMenu.show()
      else
        mobileMenu.hide()
    }
  }
}
