// Entry point for the build script in your package.json
// console.log('Vite ⚡️ Ruby')
// import '../channels'
import '../init'

import { Popover } from 'bootstrap'

import { default as phoneMasking } from 'src/application/forms_validations/rules/phone_masking'
import formsValidations from 'src/application/forms_validations'
import notExistBind from 'src/application/not_exist_binding'

window.phoneMasking = phoneMasking
window.formsValidations = formsValidations
window.notExistBind = notExistBind

formsValidations()
phoneMasking()
notExistBind()

const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
popoverTriggerList.map(popoverTriggerEl => new Popover(popoverTriggerEl))

document.querySelectorAll('a[href^="#"]').forEach(a => {
  a.addEventListener('click', function (e) {
    $('html, body').stop()
    e.preventDefault()
    const href = this.getAttribute('href')

    let scrollTop = 0

    if (href.length !== 1 && href !== '#') {
      const elem = document.querySelector(href) || document.querySelector(`a[name=${href.substring(1, href.length)}]`)
      scrollTop = elem.offsetTop
    }

    window.scroll({
      top: scrollTop,
      left: 0,
      behavior: 'smooth'
    })

    setTimeout(() => {
      window.location.hash = this.hash
    }, 200)
  })
})

// setTimeout(() => {
//   window.ym(15063154, 'getClientID', function (clientID) {
//     console.info('clientID', clientID)
//     document.getElementById('clientId').value = clientID
//     document.getElementById('proposal_client_id').value = clientID
//   })
// }, 0)

/* eslint-disable */
Number.prototype.toCurrency = function(n = 2, x = 3, s = ' ', c = '.') {
  const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')'
  const num = this.toFixed(Math.max(0, ~~n))
  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + s)
}

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

if (!Array.prototype.last) {
  Array.prototype.last = function() {
    return this[this.length - 1]
  }
}

if (!Array.prototype.first) {
  Array.prototype.first = function() {
    return this && this[0]
  }
}
/* eslint-enable */

window.onerror = (message, file, line, col, error) => {
  if (error && error.message && error.message === 'MtPopUpList is not defined') return true
}
