export default function not_exist () {
  return {
    orders_nonexist_email: {
      isPresent: {
        check: element => element.value.length > 0,
        message: 'Обязательно для заполнения'
      },
      isValid: {
        check: element => /^[A-ZА-Я0-9._%+-]+@[A-ZА-Я0-9.-]+\.[A-ZА-Я]{2,4}$/i.test(element.value),
        message: 'Неверный формат'
      }
    },
    orders_nonexist_name: {
      isPresent: {
        check: element => element.value.length > 0,
        message: 'Обязательно для заполнения'
      },
      isValid: {
        check: element => element.value.length > 5,
        message: 'Постарайтесь указать не только имя'
      }
    },
    orders_nonexist_message: {
      isPresent: {
        check: element => element.value.length > 0,
        message: 'Обязательно для заполнения'
      },
      isValid: {
        check: element => element.value.length >= 10,
        message: 'Опишите более подробно'
      }
    }
  }
}
