if (process.env.NODE_ENV === 'production') {
  import('vendor/jquery.activity.min.js').then(() => {
    $('body').activity({
      achieveTime: 60,
      testPeriod: 10,
      useMultiMode: 1,
      callBack: function () {
        console.log('Activity 60_sec')
        ga('send', 'event', 'Activity', '60_sec')
        ym(15063154, 'reachGoal', '60_sec')
      }
    })
  })
}
