if (process.env.NODE_ENV === 'production') {
  // Yandex Metrika
  (function (m, e, t, r, i, k, a) {
    m[i] = m[i] || function () {
      (m[i].a = m[i].a || []).push(arguments)
    };
    m[i].l = 1 * new Date();
    k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
  })
  (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

  ym(15063154, "init", {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    trackHash: true
  });

  // Google Tag Manager
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    });
    const f = d.getElementsByTagName(s)[0]
    const j = d.createElement(s)
    const dl = l !== 'dataLayer' ? '&l=' + l : ''
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-K2GLWDG');

  // B242YA
  if (!document.getElementById('b242ya-script')) {
    (function (w, d, u) {
      const s = d.createElement('script')
      s.defer = true;
      s.async = true;
      s.id = 'b242ya-script';
      s.src = u + '?' + (Date.now() / 60000 | 0);
      const h = d.getElementsByTagName('script')[0]
      h.parentNode.insertBefore(s, h);
    })(window, document, 'https://67p.b242ya.ru/static/js/b242ya.js');

    const b242yaScript = document.getElementById('b242ya-script')

    b242yaScript.addEventListener('load', function () {
      B242YAInit({
        portal: 'https://geotechcompany.bitrix24.ru/',
        pid: 'f02f6f7fed58cab8698958bd041a1184',
        presets: {}
      })
    })
  }

} else {
  console.log('METRICS are not loaded, because', process.env.NODE_ENV, 'environment')
}
